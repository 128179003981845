.card {
  display: grid;
  padding: 0;
  margin: 5px;
}

.caption {
  display: grid;
  align-items: end;
  justify-items: center;

  /*   padding: 1em; */

  color: var(--iah-light);
  background: var(--iah-red);
  opacity: 0;

  transition: all 0.4s;
}

.card-image {
  /*   display: inline; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
}

.caption-title {
  margin: 0;
  padding: 5px 5px 15px 5px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 0.5em;
  color: var(--iah-light);
  text-decoration: none;
}

.card-image,
.caption {
  grid-column: 1;
  grid-row: 1;
}

a:focus .caption,
a:hover .caption {
  opacity: 0.8;
}

.carousel-image {
  display: inline;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
}

.active {
  border: 4px solid var(--iah-yellow);
}

@media (min-width: 1000px) {
  .caption-title {
    font-size: 1em;
  }
}

@media (min-width: 600px) {
  .caption-title {
    font-size: 0.6em;
  }
}
